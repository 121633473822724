.hero-container {
  margin-top: 74px;
  background-image: url("../../assets/hero-bg.png");
  min-width: 100vw;
  background-size: cover;
}
.hero-content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 90vh;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
}

.hero-heading-container {
  width: 100%;
  white-space: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
.hero-heading-container > h1 > span {
  display: block;
  width: 600px;
}

.hero-heading-underline {
  margin-top: -15px;
}
.hero-content-highlight {
  display: grid;
  /* grid-template-columns: 3fr; */
  grid-template-columns: auto auto auto;
  column-gap: 15%;
  /* flex-wrap: wrap; */
  /* align-items: center; */
  justify-content: space-around;
  margin: 40px auto;
}

.hero-cards {
  margin: 20px;
  width: 200px;
  min-height: 100px;
  border: 1px solid var(--primary);
  box-shadow: 15px 10px var(--primary);
  background-color: rgba(0, 0, 0, 0.7);
  cursor: var(--pointer);
  transition: 0.4ms;
}

.hero-cards:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px var(--primary);
}

@media screen and (max-width: 870px) {
  .hero-content{
    min-height: 100vh;
  }
  .hero-content-highlight {
    grid-template-columns: auto auto;
    margin: 10px auto;
    column-gap: 20px;
  }
  .hero-heading-container > h1 > span {
    display: block;
    width: 500px;
  }
}

@media screen and (max-width: 500px) {
  .hero-container {
    overflow-x: hidden;
    max-width: 100%;
    overflow-y: hidden;
  }

  .hero-heading-underline {
    width: 90%;
    margin-top: -5px;
  }
  .hero-heading-container {
    font-size: 12px;
    width: 95%;
    margin: 40px 10px;
    white-space: wrap;
  }

  .hero-content-highlight {
    grid-template-columns: auto auto;
    margin: 10px auto;
    column-gap: 20px;
    margin-bottom: 100px;
  }
  .hero-cards {
    width: 120px;
    font-size: 12px;
    min-height: 70px;
  }
}
