
.logo{
    text-decoration: none;
    font-family: 'Dawning of a New Day',cursive;
    color:var(--light);
    font-size: 40px;
    transition: 0.4s;
}

.logo-container{
  align-self: center;
}

.logo:hover{
    color:var(--primary);
}

.navbar{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    position: fixed;
    top:0;
    z-index: 5;
    width: 100vw;
    background-color: rgba(0,0,0,1);
    min-width: 412px;
    height: 75px;

}

.nav-icon-container{
    display: flex;
    justify-content: space-around;
    width: 180px;
    /* width: 15%; */
}
.navbar-icon{
    display: grid;
    place-content:center;
    width: 50px;
    height: 50px;
    font-size: 22px;
    color: var(--light);
    transition: 0.4s;
    cursor:var(--pointer);
}
.navbar-icon:hover{
    font-size: 30px;
    color:var(--primary)
}


.hamburger-container{
    display: grid;
    place-content: center;
    padding: 10px 2px;

}

.hamburger, .close-hamburger{

    width: 60px;
    height: auto;
    cursor:var(--pointer);

}

.ham-span{
    display: block;
    width: 40px;
    height: 2px;
    margin: 5px auto;
    border-radius: 10px;
    background-color: var(--light);
    transition: 0.4s;
}

.hamburger-container:hover span{
    background-color: var(--primary);
}

/* .hamburger-container:hover span:nth-child(1){
    transform: translate(8px,2px);

    rotate: 45deg;
}
.hamburger-container:hover span:nth-child(2){
    display: none;
}
.hamburger-container:hover span:nth-child(3){
    transform: translateX(4px);
    rotate: -45deg;
} */



/* close hamburger */


.close-hamburger:hover span{
    background-color: var(--primary)
}

.close-hamburger span:nth-child(1){
    transform: translate(8px,2px);
    rotate: 45deg;
}

.close-hamburger span:nth-child(2){
    display: none;
}

.close-hamburger span:nth-child(3){
    transform: translate(4px);
    rotate: -45deg;
}
/* close hamburger */


.dropdown-close{
    position: absolute;
    top:0;
    right: 0;
    margin: 20px;
}

.show-nav-items{
    position: absolute;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 50px;
    font-weight: 800;
    background-color: var(--dark);

    top:0;
    left: 0; 
}

.nav-item{
    text-decoration: none;
    color:var(--light);
    margin: 10px 30px;
    position: relative;
}
/* .nav-item:hover{
    color:var(--primary);
    cursor: var(--pointer);
} */

.nav-item::after{
    content:'';
    height: 3px;
    width: 0%;
    background: var(--primary);
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.4s;

}

.nav-item:hover::after{
    width: 100%;
}

@media screen and (max-width:1000px) {
    .nav-icon-container{
        display: none;
    }
    

} 