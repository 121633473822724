.projects-container{
 text-align: center;
 display: flex;
 flex-wrap: wrap;
 align-items: center;
 justify-content: center;
}

.project-card{
    width:25%;
    height: 300px;
    padding: 10px;
    margin: 10px;
    border: 1px solid var(--light);
    transition: 0.4s;

    position: relative;
    cursor: var(--pointer);

}

.project-card :hover {
    transform: scale(1.1);
    border: 1px solid var(--primary);
 }

.project-card-title{
    opacity: 100%;
    width: 50%;
    position: absolute;
    top:15px;
    left:15px;
    /* left:-30px; */
    background-color: var(--primary);
    color:var(--light);
    border-radius: 5px;
    /* transition: 0.8s; */
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.project-card-title >h5{
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
}



.project-img-container{
    width: 100%;
    height: 300px;
    overflow: hidden;
}

.project-img{
    width: 100%;
    height: 300px;
    /* object-fit: contain; */
    transition: 2s;
    filter: grayscale(50%);
}



.project-card :hover .project-img{
    filter: grayscale(0%);
    transform: translateY(-200px);
}

@media screen and (max-width:900px) {
    .project-card{
        width: 40%;
    }
    .project-card, .project-img-container{
        height: 250px;
        
    }
    .project-img{
        width: 100%;
    }
}

@media screen and (max-width:500px) {
    .project-card{
        width: 70%;
    }
}

