.about-container {
  text-align: center;
}

.about-me-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px auto;
  gap: 30px;
  /* max-width: 1200px; */
  width: 100%;
}

.about-me-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 40%;
  height: 660px;
  background-color: rgb(18, 18, 18);
  filter: grayscale(10);
  transition: 0.4s;
  border: 0.5px solid gray;
}

.about-me-avatar >div{
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-me-avatar img {
  height: 150px;
  width: 150px;
  transition: 0.4s;
}

.about-me-avatar p {
  opacity: 50%;
  margin: 10px;
  white-space: wrap;
  /* transition: 0.4s; */
  width: 90%;
  height: 30%;
  /* position: absolute;
  bottom: 0; */
  display: block;
  align-items: center;
  white-space:wrap;
  justify-content: center;
  line-height: 1.6;
}

.about-me-avatar:hover {
  filter: grayscale(0);
  border: 1px solid var(--primary);
}
.about-me-avatar:hover p {
  display: block;
  opacity: 100%;
}
.about-me-avatar:hover img {
  height: 200px;
  width: 200px;
}

.about-me-calender{
    background-color: #222020;
    align-items: center;
    display: flex;
    border: 0.5px solid gray;
    width: 40%;
}

.about-me-calender:hover{
    border: 1px solid var(--primary);
}
@media screen and (max-width: 1000px) {
  .about-me-content {
    width: 70%;
    display: flex;
    flex-direction: column;
  }
  .about-me-avatar {
    border: 1px solid var(--primary);
    height: 450px;
    width: 100%;
  }
  .about-me-avatar img {
    height: 100px;
    width: 100px;
    transition: 0.4s;
  }
  .about-me-avatar:hover img {
    height: 120px;
    width: 120px;
  }
  .about-me-avatar >P{
    font-size: 14px;
  }
  .about-me-calender{
    width: 100%;
  }


}
