.calendly{
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   height: 100%;
  
   width: 100%;
}

.calendly-header{
    width: 100%;
    color: white;
    font-size: 12px;
    text-align: center;
    height: 10%;
   
    font-weight: 700;
}

.inlineWidget{ 
    height: 90%;
    width: 100%;
}


@media screen and (max-width:600px) {
    .inlineWidget{
        overflow-x: scroll;
    }
}