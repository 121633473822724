:root{
  --primary: #d61c4e;
  --dark : #060607;
  --light: rgb(255,255,255);

  --pointer: url('./assets/cursor.svg'), auto;
  --pointer-OL: url('./assets/external-pointer.svg'), auto;
  overflow-x: hidden;

}

::-moz-selection{
  color: var(--dark);
  background-color: var(--primary);

}

::selection{
  color: var(--dark);
  background-color: var(--primary);
}


body {
  margin: 0;
  font-family: 'Maven Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url('./assets/cursor.svg') auto;
  background-color: var(--dark);
  color: var(--light);
}

a,Link{
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
