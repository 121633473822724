.footer-container{
    text-align: center;
}

.footer-nav-container, .footer-navigation, .footer-socials{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 30px auto;
}
.footer-navigation{
    width: 30%;
}
.footer-socials{
    width: 10%;
}

.footer-nav-item,.footer-social-icon{
    text-decoration: none;
    color: var(--light);
    margin: 10px;
    font-size: 18px;
}
.footer-last{
    display: flex;
    /* width: 75%; */
    align-self: center;
    justify-content: space-between;
    align-items: center;
    margin: 0 10%;
}
