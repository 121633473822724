.service-container{
   display:  grid;
   place-content: center;
   text-align: center;
   min-height: 450px;
   height: auto;
   margin: 50px auto;

}
.service-cards-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    margin-top: 30px;

    gap: 100px;
}
.service-card{
    border: 1px solid var(--primary);
    width: 15%;
    padding: 30px;
    height: 150px;
   
}

.service-card-icon{
    height: 50%;
    width: 100%;
    font-size: 50px;
    color: var(--primary);
}

.service-card:hover .service-card-icon{
    transform: scale(1.5);
    opacity: 0.8;
    color:var(--light)
}

.service-card:hover h4{
    color: var(--primary);
}


@media screen and (max-width:600px){
    .service-container{
        height: auto;
        margin: 40px auto;
    }
    .service-cards-container{
        flex-direction: column;
    }
    .service-card{
        width: 50%;
    }

}